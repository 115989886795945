import React from 'react'
import {
  Breadcrumbs,
  Construction,
  Hero,
  Page,
  ServiceContact,
} from '@components'
import { buildLocalizedPath } from '@utils/functions'

const meta = {
  title: 'Izvođenje radova protupožarne zaštite | FSB d.o.o.',
  description: 'U svrhu ispunjavanja visoko postavljenih zahtjeva surađujemo s najboljim monterskim tvrtkama u Republici Hrvatskoj.',
  image: require('@assets/images/about.jpg'),
}

const Homepage = ({ pageContext }) => (
  <Page
    isHomepage
    meta={meta}
    locale={pageContext.locale}
  >
    <Breadcrumbs
      links={[
        { link: buildLocalizedPath('/', pageContext.locale), name: 'header.link.home'},
        { link: buildLocalizedPath('/services/', pageContext.locale), name: 'header.link.services'},
        { link: buildLocalizedPath('/services/execution-of-fire-protection-works/', pageContext.locale), name: 'service.1.hero.subtitle'},
      ]}
    />
    <Hero
      title="service.1.hero.title"
      subtitle="service.1.hero.subtitle"
      description="service.1.hero.description"
    />
    <Construction locale={pageContext.locale} />
    <ServiceContact id="izvođenje" locale={pageContext.locale} />
  </Page>
)

export default Homepage